.ListItem {
  margin-bottom: 0.75rem;
}

.DisabledAnchorStyling {
  pointer-events: none !important;
}

.DisabledAnchorStyling :global(.text-decoration-underline) {
  text-decoration: none !important;
}

.GridSm {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

.GridLg {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 1rem;
}
