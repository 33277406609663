.fillet {
  position: absolute;
  bottom: -29px;
  right: -5px;
  width: 0;
  height: 0;
  border: 90px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0;
  border-left: 0;
  transform: rotate(198deg);
}

.iconDelete {
  opacity: 0.3;
  transition: 0.4s opacity;
}

.iconDelete:hover {
  opacity: 1;
}

.innerContainer {
  width: 100%;
}
