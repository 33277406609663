.open {
  transform: rotate(0deg);
  transition: rotate 0.4s;
}

.closed {
  transform: rotate(-90deg);
  transition: rotate 0.4s;
}

.mr_n3,
.mx_n3 {
  margin-right: -1rem !important;
}
.ml_n3,
.mx_n3 {
  margin-left: -1rem !important;
}
