.root {
}
.root .contentContainer {
  min-height: 152px;
}
.root .nakedContentContainer {
  margin-bottom: -2.2rem !important;
}

@media only screen and (max-width: 576px) {
  .fullBleed {
    margin-left: 0px !important;
    width: 100vw !important;
  }
}

@media only screen and (min-width: 576px) {
  .fullBleed {
    margin-left: calc(var(--container_width, 100%) / 2 - 50vw) !important;
    width: 100vw !important;
  }

 :global(.text-direction-rtl) .fullBleed {
    margin-right: calc(var(--container_width, 100%) / 2 - 50vw) !important;
    margin-left: unset;
  }
}

:global(#modal-page-item .page-item-page-header) {
  display: none;
}
