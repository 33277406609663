:root {
	--video-player-subtitles-background-color: rgba(0, 0, 0, 0.6);
	--video-player-subtitles-text-color: white;
	--video-player-subtitles-border-radius: 42px;
	--video-player-subtitles-min-height: 180px;
	--video-player-subtitles-padding: 2rem 3rem;
	--video-player-subtitles-font-size: 42px;
}

:global(.program-type-slide) .overlay {
	--video-player-subtitles-background-color: rgba(0, 0, 0, 0.6);
	--video-player-subtitles-text-color: white;
	--video-player-subtitles-border-radius: 42px;
	--video-player-subtitles-min-height: 180px;
	--video-player-subtitles-padding: 2rem 3rem;
}

:global(.program-type-scroll) .block {
	--video-player-subtitles-font-size: 1rem;
	--video-player-subtitles-padding: 1rem;
	--video-player-subtitles-min-height: 81px;
	--video-player-subtitles-border-radius: none;
}

:global(.program-type-scroll) .overlay {
	--video-player-subtitles-font-size: 1rem;
	--video-player-subtitles-padding: 1rem;
	--video-player-subtitles-min-height: 81px;
	--video-player-subtitles-border-radius: 1.25rem;
}

.subtitles {
	background-color: var(--video-player-subtitles-background-color);
	color: var(--video-player-subtitles-text-color);
	padding: var(--video-player-subtitles-padding);

	border-radius: var(--video-player-subtitles-border-radius);
	width: 100%;
	min-height: var(--video-player-subtitles-min-height);
	position: relative;
}

.overlay.hidden {
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s 1.5s;
}

.subtitles .subtitle {
	font-size: var(--video-player-subtitles-font-size);
	font-weight: 700;
	line-height: 1.05em;
}

.subtitle p {
	margin: 0;
}
