.root {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.root span {
  margin-top: 2px;
}

.root:hover .checkInner {
  background-color: #979797;
}

.checkOuter {
  position: relative;
  height: 29px;
  width: 29px;
  min-width: 29px;
  background-color: white;
  border: 5px solid #aaaaaf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rootSelected .checkInner {
  background-color: black;
}
