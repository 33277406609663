.root {
}

.root table {
}
.root .tableWrapper {
  overflow-x: scroll;
}

.root table td,
.root table th {
  border: 1px solid grey;
}

.root table .firstCol {
  width: 60%;
}

.root table .rowImage {
  width: 32px !important;
}

.root table th {
  border-top: 1px solid transparent;
}

.root table tr.trDisabled {
  opacity: 0.5;
}

.root table td .dot {
  margin: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.root table th:first-of-type,
.root table td:first-of-type {
  border-left: 1px solid transparent;
}

.root table th:last-of-type,
.root table td:last-of-type {
  border-right: 1px solid transparent;
}
.root table tr:last-of-type td {
  border-bottom: 1px solid transparent;
}

/* mobile */

:global(.size-xs) .root table th span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
