.container {
  flex-grow: 0;
  flex-shrink: 0;
  width: 99px;
  height: 99px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s background-color;
}

.small {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.tiny {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
