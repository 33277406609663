.numberContainer {
  width: 50px;
  height: 50px;
}

.draggableBullet {
  position: absolute;
  top: 0;
  left: 0;
}
