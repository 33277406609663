:root {
	--button-max-width: none;
	--button-min-width: 160px;

	--button-background-color: #000;
	--button-text-color: #fff;
	--button-border-color: #333;
	--button-hover-background-color: var(--fp-interaction);
	--button-hover-text-color: #fff;
	--button-hover-border-color: #444;
	--button-active-background-color: #222;
	--button-active-text-color: #fff;
	--button-active-border-color: #222;
	--button-active-transform: scale(0.97);
	--button-disabled-background-color: #bfbfbf;
	--button-disabled-text-color: #fff;
	--button-disabled-border-color: #bfbfbf;

	--button-outline-background-color: #fff;
	--button-outline-text-color: var(--button-background-color);
	--button-outline-border-color: var(--button-background-color);
	--button-outline-border-width: 2px;

	--button-outline-hover-background-color: var(--fp-interaction);
	--button-outline-hover-text-color: #fff;
	--button-outline-hover-border-color: var(--fp-interaction);
	--button-outline-hover-border-width: 2px;

	--button-outline-disabled-background-color: #fff;
	--button-outline-disabled-text-color: #bfbfbf;
	--button-outline-disabled-border-color: #bfbfbf;

	--button-focus-box-shadow: initial;
	--button-focus-outline: 5px auto Highlight;

	--button-font-family: inherit;

	--button-font-weight: bold;
	--button-border-style: none;

	--button-text-transform: uppercase;

	--button-icon-width: 1rem;
	--button-icon-height: 1rem;
	--button-icon-color: currentColor;
	--button-transition: all 0.1 ease-out;

	--button-standard-min-height: none;
	--button-small-min-height: none;

	--button-standard-padding: 0.5rem 2rem;
	--button-small-padding: 0.4rem 1rem;

	--button-standard-font-size: 0.8125rem;
	--button-small-font-size: 0.7rem;

	--button-standard-letter-spacing: 0.0869rem;
	--button-small-letter-spacing: var(--button-standard-letter-spacing);

	--button-standard-border-radius: 50rem;
	--button-small-border-radius: 50rem;

	--button-standard-border-width: 1px;
	--button-small-border-width: 1px;
}

.resetButton {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: button;
}

.commonButton {
	font-family: var(--button-font-family);
	font-weight: var(--button-font-weight);
	border-width: var(--button-border-width);
	border-style: var(--button-border-style);
	border-color: var(--button-border-color);
	border-radius: var(--button-border-radius);
	background-color: var(--button-background-color);
	color: var(--button-text-color);
	box-shadow: var(--button-box-shadow);
	text-transform: var(--button-text-transform);
	transition: var(--button-transition);
	max-width: var(--button-max-width);
	min-width: var(--button-min-width);
	cursor: pointer;

	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.fluidButton {
	width: 100%;
	max-width: none;
	min-width: none;
}

.commonButton:enabled:hover,
.commonButton:enabled:focus,
.commonButton:has(:enabled):hover,
.commonButton:has(:enabled):focus {
	background: var(--button-hover-background-color);
	color: var(--button-hover-text-color);
	border-color: var(--button-hover-border-color);
}

.commonButton:focus-visible {
	box-shadow: var(--button-focus-box-shadow);
	outline: var(--button-focus-outline);
}

.commonButton:disabled,
.commonButton:has(:disabled) {
	background-color: var(--button-disabled-background-color);
	color: var(--button-disabled-text-color);
	border-color: var(--button-disabled-border-color);
	cursor: not-allowed;
}

.commonButton:enabled:active,
.commonButton:has(:enabled):active {
	background: var(--button-active-background-color);
	color: var(--button-active-text-color);
	border-color: var(--button-active-border-color);
	transform: var(--button-active-transform);
}

.solidButton {
}

.invertedButton {
	background: var(--button-text-color);
	color: var(--button-background-color);
}

.outlineButton {
	background: var(--button-outline-background-color);
	color: var(--button-outline-text-color);
	border-color: var(--button-outline-border-color);
	border-width: var(--button-outline-border-width);
	border-style: solid;
}

.outlineButton:enabled:hover,
.outlineButton:enabled:focus,
.outlineButton:has(:enabled):hover,
.outlineButton:has(:enabled):focus {
	background: var(--button-outline-hover-background-color);
	color: var(--button-outline-hover-text-color);
	border-color: var(--button-outline-hover-border-color);
	border-width: var(--button-outline-hover-border-width);
}

.outlineButton:disabled,
.outlineButton:has(:disabled) {
	background: var(--button-outline-disabled-background-color);
	color: var(--button-outline-disabled-text-color);
	border-color: var(--button-outline-disabled-border-color);
}

.standardSizeButton {
	font-size: var(--button-standard-font-size);
	padding: var(--button-standard-padding);
	letter-spacing: var(--button-standard-letter-spacing);
	border-radius: var(--button-standard-border-radius);
	min-height: var(--button-standard-min-height);
}

.smallSizeButton {
	font-size: var(--button-small-font-size);
	padding: var(--button-small-padding);
	letter-spacing: var(--button-small-letter-spacing);
	border-radius: var(--button-small-border-radius);
	min-height: var(--button-small-min-height);
}
