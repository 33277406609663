.iconDelete {
  opacity: 0.3;
  transition: 0.4s opacity;
}

.iconDelete:hover {
  opacity: 1;
}

.innerContainer {
  max-width: 374px;
}
