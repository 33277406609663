:root {
	--badge-border-radius: 5px;
	--badge-padding: 0 0.3rem;
	--badge-font-weight: 600;
	--badge-font-size: 0.9em;
}

.badge {
	padding: var(--badge-padding);
	border-radius: var(--badge-border-radius);
	font-weight: var(--badge-font-weight);
	font-size: var(--badge-font-size);
	cursor: default;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.regular.info {
	background-color: var(--fp-info-light);
	color: var(--fp-info);
}

.strong.info {
	background-color: var(--fp-info);
	color: white;
}

.regular.neutral {
	background-color: var(--fp-neutral-light);
	color: var(--fp-neutral);
}

.strong.neutral {
	background-color: black;
	color: white;
}

.regular.positive {
	background-color: var(--fp-positive-light);
	color: var(--fp-positive);
}

.strong.positive {
	background-color: var(--fp-positive);
	color: white;
}

.regular.caution {
	background-color: var(--fp-caution-light);
	color: rgb(114, 61, 2);
}

.strong.caution {
	background-color: var(--fp-caution);
	color: rgb(114, 61, 2);
}

.regular.critical {
	background-color: var(--fp-critical-light);
	color: var(--fp-critical);
}

.strong.critical {
	background-color: var(--fp-critical);
	color: white;
}
