.speechBubble {
  transition: opacity 0.3s;
}

.speechBubbleExpanded {
  min-height: 104px !important;
}

.unselected {
  opacity: 0.2;
  transition: opacity 0.2s;
}

.selected {
  opacity: 1;
  transition: opacity 0.2s;
}

.optionText {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

:global(.feedback-bubble) {
  animation-fill-mode: forwards;
  transform: rotateX(90deg);
}
