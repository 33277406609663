.topMargin {
  /* v1 topbar is 55px, v2 topbar is 52 */
  margin-top: 52px;
}

:global(.page-menu .page-scalable) {
  overflow-y: auto;
}

.slideOuterContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
}

.slideInnerContainer {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
}

.slideMainMenuChapterContainer {
  position: relative;
  margin: 12px;
  width: 566px;
  height: 100%;
}
