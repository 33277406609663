.arrow {
  transform: rotate(-90deg);
  transition: 0.2s;
  transform-origin: center;
  margin-top: -3px;
}

.arrowOpen {
  transform-origin: center;
  transform: rotate(0deg);
}
