@media (min-width: 1366px) {
  :root {
    /* 
      16 = previous base font size
      --prefered_font_size =  calc(16 / 1280) = 0.0156 = 1.56vw; 
      --prefered_font_size =  calc(16 / 1280) = 0.0125 = 1.25vw; 
      --prefered_font_size =  calc(16 / 1366) = 0.0117 = 1.17vw; 
      means fonts will start "scaling linearly" at that point, from 16 up to the max. 
    */
    /* 
      --max_scale_factor is how much we allow the rem to scale
      You can calculate this if you know the max base font-size (after which scaling should stop)
      Or if you know the max container size (after which the scaling should stop)

      Eg.
      Maximum allowed value DIVIDED by Base value.
      Font-size based:
      18px / 16px  = 1.125
      20px / 16px = 1.25
    */
    --max_scale_factor: 1.125;
    /*  clamp(min, prefered, max)  */
    --base_font_size: 16px;
    --max_font_size: calc(var(--max_scale_factor) * var(--base_font_size));
    font-size: clamp(var(--base_font_size), 1.17vw, var(--max_font_size))!important;
    --min_container_width: 0px;
    /* 
      864 = previous max width.
      Using this along with the break point means we reach the break-point, and 
      then the max-width starts "scaling linearly" to the maximum without any "jumps"

      864 / 1024 = 0.843 = 84.3vw
      864 / 1280 = 0.675 = 67.5vw
      864 / 1366 = 0.633 = 63.3vw
    */
    --prefered: 63.3vw;
    /* 
    if we want to be able to use image heights in rem, and be sure 
    that a height that allows for desktop 100% width (at 864px base width), is STILL 100% at some scaling,
    we need to ensure that this max container width scales with the same factor that the rem scaling is based on.

    note that wthe container max width is 864px, but the image max-width is thought of as 800px (excluding 32px * 2 padding)
    */
    --base_max_container_width: 864px;
    --max-container_width: calc(var(--base_max_container_width) * var(--max_scale_factor));
    --container_width: clamp(var(--min_container_width), var(--prefered), var(--max-container_width));
  }
  .program-type-scroll .page-container-portrait {
    max-width: var(--container_width);
  }
}
