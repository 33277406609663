.button:hover {
  text-decoration: underline;
}

.button > span {
  margin-left: 0;
  transition: 0.3s margin-left;
}

.button:hover > span {
  margin-left: 10px;
}
