.reveal {
  overflow: hidden;
  transition: max-height 0.5s ease-in;
  /* needs to be a fixed value, but this can be increased if needed*/
  max-height: 9999px;
  padding-bottom: 40px;
}

.unreveal {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in;
  transition-delay: 0.5s;
}
