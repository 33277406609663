.root {
  user-select: none;
}

.itemsContainer {
  min-width: 248px;
  position: relative;
  top: -10px;
  left: 0;
  height: 220px;
}

.item {
  /* transition: opacity 0.2s ease-in-out; */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  padding: 1rem;
  box-sizing: border-box;
  width: 248px;
  max-width: 104%;
  height: 102px;
  text-align: left;
  border-radius: 0px;
  box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.70);
}

.item.itemDragging {
  opacity: 0;
}
/* "preview" */
.item.itemMoving {
  z-index: 99 !important;
  cursor: move !important;
}

.item.itemConsumed {
  opacity: 0;
}

.item:global(.index-0) {
  /* background-color: #9c9c9c; */
  z-index: 3;
}
.item:global(.index-1) {
  /* background-color: #676767; */
  z-index: 2;
}
.item:global(.index-2) {
  /* background-color: #424242; */
  z-index: 1;
}

.targetsContainer {
}
.targetsContainer.targetsShowCorrect .itemFeedbackIcon {
  /* animation-name: flash   - this is added with a class. couldn't get it to work because "flash" is global */
  animation-iteration-count: 2;
  animation-duration: 500ms;
}

.target {
  transition: background-color 0.2s ease-in-out;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 176px;
  outline-style: dashed;
  outline-width: 10px;
  outline-offset: calc(-10px / 2);
  max-width: 33%!important;
  overflow: hidden;
}

.target.activeTarget {
  color: white;
  background-color: rgba(216, 215, 213, 0.9);
}

:global(.drop-target-label-text) {
  position: absolute;
  top: 0;
  left: 10px;
  width: 93%;
  /* opacity: 1;
  transition: opacity ease 0.5s; */
  line-height: 1.2rem;
  padding: 4px;
}

.target:global(.has-child-item) :global(.drop-target-label-text) {
  opacity: 0.5;
  z-index: -1;
}

.targetPlaceholder {
  display: block;
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  text-align: center;
}
.targetPlaceholder.targetPlaceholderWithImage {
  top: 85%;
}

.target .item {
  /* small adjust needed because of dropshaddow hack */
  margin-right: -10px;

  margin-top: auto;
  margin-bottom: 10px;
}

.target .itemFeedbackIcon {
  position: absolute;
  right: 0px;
  top: 10px;
  width: 45px;
  height: 45px;
}

@media screen and (max-width: 576px) {
  .target {
    height: 110px;
    max-width: none!important;
  }
  .target .item {
    /* transform: scale(0.9); */
    max-height: 50px;
    overflow: hidden;
  }
  .target .itemFeedbackIcon {
    width: 35px;
    height: 35px;
  }
}