.outerContainer {
  /* pointer-events: none; */
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.innerContainer {
  padding-top: 55px;
  top: 0;
  left: 0;
  width: 512px;
  max-width: 100vw;
  height: 100%;
  background-color: red;
  transition: 0.4s;
}

.openLeft {
  margin-left: 0;
  overflow-y: auto;
}

.closedLeft {
  margin-left: -512px;
}

.openRight {
  margin-right: 0;
  overflow-y: auto;
}

.closedRight {
  margin-right: -512px;
}
