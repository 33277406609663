.outerContainer {
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  min-height: min(454px, 60%);
  max-width: 406px;
  max-height: 75%;
}

.contentContainer {
  overflow-y: auto;
  overscroll-behavior: contain;
}

.closeButton {
  margin: 0.75rem;
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  padding: 0 !important;
  border-color: transparent;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}
