.root {
  position: relative;
  box-sizing: border-box;
  width: 555px;
  height: 242px;
  z-index: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .root {
    border: 0.4px solid transparent;
  }
}

.modeScroll {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23AAAAAF' stroke-width='1' stroke-dasharray='10%2c 1' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.buttonChooseContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}

/* :global(.program-type-slide) .buttonChooseContainer .chooseButton {
  transition: opacity 0.2s ease-in;
  opacity: 0;
}
:global(.program-type-slide) :global(.dilemma-card):hover .buttonChooseContainer .chooseButton {
  opacity: 1;
} */

.readMoreBubble {
  position: absolute;
  right: 0;
  top: 0;
}

.overlayIcons {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  opacity: 0.4;
}
