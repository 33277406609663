.image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}

:global(.size-mobile) .image {
  background-position: center;
}

.arrow {
  transform: rotate(-90deg);
  transition: 0.2s;
  margin-top: 5px;
  margin-left: -4px;
}

.arrowOpen {
  margin-top: 0;
  margin-left: 0;
  transform: rotate(0deg);
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.border {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ChapterStatusTilted {
  min-width: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

/* SLIDE STYLING */

.slideChapterButton {
  position: relative;
  padding: 24px 24px 50px;
  display: flex;
  border: 1px solid transparent;
  transition: border-color 0.4s;
}

.slideImage {
  overflow: hidden;
  width: 222px !important;
  height: 222px !important;
  min-width: 222px !important;
  min-height: 222px !important;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  margin-right: 24px;
}

.slideChapterIdentifier {
  margin-bottom: -4px;
}
