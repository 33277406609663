.root {
}

.root .box .info {
  min-height: 98px;
}

/* modes */

.root.Clip .box .clipImage {
  position: absolute;
  top: -38px;
  left: -26%;
}
.root.Clip .box .info {
  padding-top: 100px !important;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3);
  min-height: 198px;
}

.root.RippedPaper .jaggedBottom {
  position: relative;
  /* overlap because some resolutions show a gap otherwise */
  top: -6px;
  left: 0px;
  margin-bottom: -60px;
}

/* mobile */
:global(.size-xs) .root .box {
  transform: none!important;
}

:global(.size-xs) .root.Clip .box .clipImage {
  left: 0px;
}