.container {
  width: 1280px;
  max-height: 720px;
  margin-top: 286px;
}

.groupUnitListItems {
  max-height: 360px;
  overflow-y: auto;
}

.groupUnitListItemsMask {
  padding-bottom: 120px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
