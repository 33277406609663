:root {
	--divider-regular-color: rgb(210, 215, 223);
	--divider-strong-color: rgb(46, 56, 73);
	--divider-height: 1px;
}

.divider {
	margin: 0;
	border: 0;
	height: var(--divider-height);
	background: var(--divider-color);
}

.regular {
	--divider-color: var(--divider-regular-color);
}

.strong {
	--divider-color: var(--divider-strong-color);
}
