:global(.program-type-slide) {
	--video-player-subtitle-container-padding: 0 10rem 1rem 10rem;
}

:global(.program-type-scroll) {
	--video-player-subtitle-container-padding: 0 2rem 1rem 2rem;
}

.buttonRoundedMedium {
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.subtitleContainer {
	width: 100%;
	padding: var(--video-player-subtitle-container-padding);
	position: absolute;
	bottom: 0;
}

.subtitle {
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
}

.progressBar {
	position: absolute;
	bottom: 1px;
	left: 0;
	height: 3px;
	width: 100%;
	transition: height 0.2s;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

.progressBarBig {
	height: 10px;
}

.progressBarSlider:global(.rc-slider) {
	position: absolute;
	height: 100%;
	width: 100%;
	/* unset some styles from rc-slider */
	padding: unset;
}

.progressBarSlider :global(.rc-slider-track) {
	/* unset some styles from rc-slider */
	border-radius: unset;
}
.progressBarSlider :global(.rc-slider-handle) {
	/* unset some styles from rc-slider */
	margin-top: unset;
}
.progressBarSlider :global(.rc-slider-rail) {
	/* unset some styles from rc-slider */
	background-color: unset;
	border-radius: unset;
}

.videoControlsContainer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.videoControlsButtonContainer {
	/**
	We don't want this element to respond to click events
	since we want to catch them in a parent element.
	**/
	pointer-events: none;
}

:global(.program-type-slide) .videoControlsContainer {
	padding: 0 178px 16px !important;
}
:global(.program-type-slide) .videoControlsButtonContainer {
	transform: scale(1.9);
}
