:root {
	--choice-border-radius: 5px;
	--choice-min-height: 92px;
	--choice-padding: 1rem;

	--choice-idle-background-color: white;
	--choice-idle-text-color: #222;
	--choice-idle-border-color: #aaa;
	--choice-idle-border-width: 1px;

	--choice-hover-background-color: white;
	--choice-hover-text-color: #222;
	--choice-hover-border-color: #222;
	--choice-hover-border-width: 1px;

	--choice-selected-background-color: white;
	--choice-selected-text-color: #222;
	--choice-selected-border-color: var(--fp-interaction);
	--choice-selected-border-width: 2px;

	--choice-disabled-background-color: white;
	--choice-disabled-text-color: #dfdfdf;
	--choice-disabled-border-color: #dfdfdf;
	--choice-disabled-border-width: 1px;
}

.choiceGroup {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.4rem;
}

:global(.size-mobile) .choiceGroup {
	grid-template-columns: 1fr;
}

.choiceItem {
	display: block;
	position: relative;
	margin: 0;
	padding: var(--choice-padding);

	cursor: pointer;
	color: var(--choice-idle-text-color);
	min-height: var(--choice-min-height);

	border-style: solid;

	background-color: var(--choice-idle-background-color);
	border-color: var(--choice-idle-border-color);
	border-width: var(--choice-idle-border-width);
	border-radius: var(--choice-border-radius);
}

.disabled {
	cursor: not-allowed;
	color: var(--choice-disabled-text-color);

	background-color: var(--choice-disabled-background-color);
	border-color: var(--choice-disabled-border-color);
	border-width: var(--choice-disabled-border-width);
}

.enabled:not(.selected):hover {
	color: var(--choice-hover-text-color);
	background-color: var(--choice-hover-background-color);
	border-color: var(--choice-hover-border-color);
	border-width: var(--choice-hover-border-width);
}

.selected {
	cursor: default;
	color: var(--choice-selected-text-color);

	background-color: var(--choice-selected-background-color);
	border-color: var(--choice-selected-border-color);
	border-width: var(--choice-selected-border-width);
}

.choiceItem input[type="radio"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: #fff;
	/* Not removed via appearance */
	margin: 0;
}

/** Variant speech bubble drama **/

.variantSpeechBubbleDrama {
	--choice-border-radius: 19px;
	--choice-padding: 1rem 1rem 2rem 1rem;

	--choice-idle-background-color: var(--fp-interaction);
	--choice-idle-text-color: #333;
	--choice-idle-border-color: var(--fp-interaction);

	--choice-hover-background-color: var(--choice-idle-background-color);
	--choice-hover-text-color: var(--choice-idle-text-color);
	--choice-hover-border-color: var(--choice-idle-border-color);

	--choice-selected-background-color: black;
	--choice-selected-text-color: #fff;
	--choice-selected-border-color: black;
	--choice-selected-border-width: 1px;

	--choice-disabled-background-color: var(--choice-idle-background-color);
	--choice-disabled-text-color: var(--choice-idle-text-color);
	--choice-disabled-border-color: var(--choice-idle-border-color);
}

.variantSpeechBubbleDrama .choiceItem {
	margin-bottom: 20px;
}

.variantSpeechBubbleDrama .choiceItem.enabled:not(.selected):hover {
	filter: brightness(1.1);
}

.variantSpeechBubbleDrama .choiceItem::after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 20%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: var(--choice-idle-background-color);
	border-bottom: 0;
	border-right: 0;
	margin-left: -10px;
	margin-bottom: -20px;
}

.variantSpeechBubbleDrama .enabled:not(.selected):hover::after {
	border-top-color: var(--choice-hover-background-color);
}

.variantSpeechBubbleDrama .selected::after {
	border-top-color: var(--choice-selected-background-color);
}

.variantSpeechBubbleDrama .disabled:not(.selected) {
	filter: brightness(1.1) opacity(0.3);
}

.variantSpeechBubbleDrama .disabled:not(.selected)::after {
	border-top-color: var(--choice-disabled-background-color);
}

/** Variant speech bubble serious **/

.variantSpeechBubbleSerious {
	--choice-border-radius: none;
	--choice-padding: 0.5rem 1rem 2rem 1rem;

	--choice-idle-background-color: var(--fp-interaction);
	--choice-idle-text-color: #333;
	--choice-idle-border-color: var(--fp-interaction);

	--choice-hover-background-color: var(--choice-idle-background-color);
	--choice-hover-text-color: var(--choice-idle-text-color);
	--choice-hover-border-color: var(--choice-idle-border-color);

	--choice-selected-background-color: black;
	--choice-selected-text-color: #fff;
	--choice-selected-border-color: black;
	--choice-selected-border-width: 1px;

	--choice-disabled-background-color: var(--fp-interaction);
	--choice-disabled-text-color: #333;
	--choice-disabled-border-color: var(--fp-interaction);
}

.variantSpeechBubbleSerious .choiceItem {
	margin-bottom: 20px;
}

.variantSpeechBubbleSerious .choiceItem.enabled:not(.selected):hover {
	filter: brightness(1.1);
}

.variantSpeechBubbleSerious .choiceItem::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: var(--choice-idle-background-color);
	border-bottom: 0;
	margin-left: -20px;
	margin-bottom: -20px;
}

.variantSpeechBubbleSerious .enabled:not(.selected):hover::after {
	border-top-color: var(--choice-hover-background-color);
}

.variantSpeechBubbleSerious .selected::after {
	border-top-color: var(--choice-selected-background-color);
}

.variantSpeechBubbleSerious .disabled:not(.selected) {
	filter: brightness(1.1) opacity(0.3);
}

.variantSpeechBubbleSerious .disabled:not(.selected)::after {
	border-top-color: var(--choice-disabled-background-color);
}
