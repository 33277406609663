.activityLink {
	--activity-link-outline-color: var(--fp-interaction);
	display: block;
}
.activityLink h1,
.activityLink h2,
.activityLink h3,
.activityLink p {
	margin: 0;
}

.activityIdentifier.activityIdentifier {
	font-size: 4rem;
	line-height: 0.8;
}

.activityLink:hover {
	text-decoration: none;
	color: inherit;
	outline: 2px solid var(--activity-link-outline-color);
	outline-offset: 0.5rem;
}
