:root {
	--participants-avatar-background-color: var(--fp-neutral-light);
	--participants-avatar-text-color: var(--fp-neutral);
	--participants-avatar-size: 4rem;
}

.groupMembers {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	font-size: 0.8em;
}

@media only screen and (min-width: 850px) {
	.groupMembers {
		grid-template-columns: repeat(4, 1fr);
	}
}

.liReset {
	margin: 0;
}

.groupMember {
	overflow: hidden;
}

.name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.roleHeader {
	color: var(--fp-neutral);
	font-style: italic;
}

.role {
	color: var(--fp-neutral);
}

.avatar {
	background-color: var(--participants-avatar-background-color);
	color: var(--participants-avatar-text-color);
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--participants-avatar-size);
	height: var(--participants-avatar-size);
	font-weight: bold;
	font-size: 1.5rem;
}
