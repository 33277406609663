.root {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.root:hover .checkInner {
  background-color: #979797;
}

.circleOuter {
  position: relative;
  height: 35px;
  width: 35px;
  min-width: 35px;
  background-color: white;
  border-radius: 50%;
  border: 5px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleInner {
  opacity: 0.5;
  position: relative;
  height: 17px;
  width: 17px;
  min-width: 17px;
  background-color: white;
  border-radius: 50%;
  border: 5px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rootSelected .checkInner {
  background-color: black;
  border-color: black;
}

.iconDot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
