.defaultBullet {
  width: 53px;
  height: 53px;
}

.image {
  width: 53px;
  height: 53px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

.textContainer {
  margin-top: -6px;
  display: flex;
}

.textContainer h5 {
  margin-top: 4px !important;
}
