:root {
	--details-text-color: initial;
	--details-divider-color: canvastext;
	--details-icon-color: canvastext;
}

.details {
	--divider-regular-color: var(--details-divider-color);
	--divider-height: 2px;
	width: 100%;
}

.toggleButton {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	color: var(--details-text-color);
	font-style: italic;
	padding: 0.5rem 0;
}

.toggleButton:hover {
	--details-icon-color: var(--fp-interaction);
	color: var(--fp-interaction);
	text-decoration: underline;
}

.icon {
	transition: transform 0.2s;
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;

	border-left: 11px solid var(--details-icon-color);
}

.collapsed .icon {
	transform: rotate(0deg);
}

.expanded .icon {
	transform: rotate(90deg);
}
