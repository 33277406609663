.backgroundImageContain {
  background-size: contain !important;
}

.backgroundImageCover {
  background-size: cover !important;
  background-position: top left!important;
}

.backgroundImageCoverWidth {
  background-size: 100% auto !important;
  background-position: top left!important;
}
/* SCROLL */

.scrollWrapperButton {
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  height: 100% !important;
  width: 100% !important;
}

.scrollBackgroundImage {
  background-repeat: no-repeat;
  background-position: center;
}

/* SLIDE */
.slideBackgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 100% !important;
  width: 100% !important;
}

/* make outer container set the size */
.scrollSplashIcon {
  width: 100%!important;
  height: 100%!important;
  background-size: contain;
  background-repeat: no-repeat;
}

.scrollSplashLogoContainer:global(.logo-container) {
  left: unset;
  right: unset;
}