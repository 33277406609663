.topBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 3;
  transition: transform 300ms linear;
  transition-delay: 0.1s;
  top: 0;
  will-change: transform;
  pointer-events: none;
}

.dropShadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.innerContainer {
  height: 52px;
}

.breadCrumbs {
  min-width: 150px;
  max-width: 50vw;
}

.progress {
  height: 0.25rem !important;
  border-radius: 0px !important;
}

.menuButton {
  height: 100%;
  width: 150px;
  display: flex;
  align-items: center;
}

.menuButtonRight {
  justify-content: flex-end;
}

.titleAndProgressWrapper {
  margin-bottom: 5px;
}

.logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}

.logoRight {
  background-position: right center;
}

.logoLeft {
  background-position: left center;
}
