.root {
  display: flex;
}

.image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 140px !important;
  height: 140px !important;
  flex-shrink: 0;
}

.textContainer {
  width: 100%;
}

.textContainer h3,
.textContainer h5 {
  flex-grow: 0;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.border {
  position: absolute;
  width: 100%;
  height: 100%;
}

.infoBar h5 {
  margin-top: 8px;
  white-space: nowrap;
}
