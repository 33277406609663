.defaultBullet {
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 1.1875rem;
}

.emphasizedBullet {
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 1.6875rem;
}
