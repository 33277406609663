@keyframes rotate {
  0% {
    transform: translate3d(0px, 0px, 100px) rotateY(0deg);
  }
  100% {
    transform: translate3d(0px, 0px, 100px) rotateY(360deg);
  }
}

.root {

}

.root.animateRotation {
  animation: rotate 5s linear infinite;
}