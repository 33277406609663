.row {
	/* 	The custom property "--rate-output-custom-result-color" is set from
		page item based on color from FAT.
	*/
	--rate-output-result-color: var(
		--rate-output-custom-result-color,
		var(--fp-interaction)
	);

	--slider-color: var(--rate-output-result-color);

	display: flex;
	gap: 2rem;
}

.marker {
	text-transform: uppercase;
	line-height: 0.8 !important;
}

.marker::after {
	content: ".";
}
