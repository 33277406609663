.topBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transition: transform 300ms linear;
  transition-delay: 0.1s;
  top: 0;
  will-change: transform;
  height: 85px;
  background-color: white;
  pointer-events: none;
  box-shadow: 0 5px 6px -6px gray;
}

:global(.cmt-page-type-print) {
  max-width: 864px;
}

:global(.result-header) {
  margin-top: 94px !important;
}
