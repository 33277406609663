:root {
	--spacing-base-value: 1rem;
}

.stack {
	display: flex;

	gap: var(--stack-gap, 0);
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

.fit-content-width {
	width: fit-content;
}

.fit-content-height {
	height: fit-content;
}

.horizontal {
	flex-direction: row;
}

.vertical {
	flex-direction: column;
}

.none {
	--stack-gap: 0;
}

.xxsmall {
	--stack-gap: calc(var(--spacing-base-value) / 8);
}

.xsmall {
	--stack-gap: calc(var(--spacing-base-value) / 4);
}

.small {
	--stack-gap: calc(var(--spacing-base-value) / 2);
}

.medium {
	--stack-gap: var(--spacing-base-value);
}

.large {
	--stack-gap: calc(var(--spacing-base-value) * 2);
}

.xlarge {
	--stack-gap: calc(var(--spacing-base-value) * 4);
}

.xxlarge {
	--stack-gap: calc(var(--spacing-base-value) * 8);
}

.start {
	justify-content: start;
}

.center {
	align-items: center;
}

.end {
	justify-content: end;
}
