.root {
}

.root .clickIcon {
  width: 3.75rem;
  height: 3.75rem;
}

.lightBoxContentContainer {
  overflow: visible !important;
}

.lightBoxInnerContainer {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  /* TODO figure out if x-light-grey should be added as template color */
  background-color: #f4f4f4 !important;
  max-height: 85vh;
  overflow: scroll;
}

.lightBoxInnerContainer .lightBoxImage {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
  width: calc(100% + 3rem) !important;
}


.root :global(#hotspot-outer-circle) {
  animation-name: circlePulsate;
  animation-iteration-count: 999;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  /* somehow needed to center the tranformation properly */
  transform-origin: 66% 66%;
  animation-timing-function: ease-in-out;
}

@keyframes circlePulsate {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  10% {
    opacity: 0;
    transform: scale(0.8);
  }
  88% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}