.root {
	position: relative;
	width: 100%;
	line-height: 0;
	overflow: hidden;
}

.video {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
}

.crop {
	object-fit: cover;
}

.childrenContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.sign {
	position: absolute;
	left: 0;
	top: 0;
}

/* Fix for safari still showing sidemeny and topbar when in native video fullscreen mode */
:global(.fullscreen-video-active) :global(.side-menu),
:global(.fullscreen-video-active) :global(.top-bar) {
	visibility: hidden;
}
