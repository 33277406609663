:root {
	--file-drop-area-preview-aspect-ratio: 3/2;
	--file-drop-area-preview-background-color: lightgray;
}

.fileDropArea {
	border: 1px dashed black;
	padding: 1rem;
}

.highlight {
	border-color: var(--fp-interaction);
}

.fileDropArea label input {
	display: none;
}

.previewArea {
	aspect-ratio: var(--file-drop-area-preview-aspect-ratio);
	background-color: var(--file-drop-area-preview-background-color);
	width: 100%;
	max-width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.previewArea .toolbar {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.previewArea .toolbar button {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.8);
}

.uploadedImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
