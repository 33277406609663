.outerContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.innerContainer {
  position: relative;
}

.textContainer {
  position: relative;
}
