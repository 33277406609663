.root {
  width: 36px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  height: 4px;
  width: 100%;
  transform-origin: center;
  /* opacity: 1; */
  transition: 0.4s;
}

.open_line_1 {
  transform: translateY(10px) rotate(0.125turn);
}
.open_line_2 {
  transform: translateY(2px) rotate(-0.125turn);
}

.open_line_3 {
  transform: translateY(-6px) rotate(0.125turn);
}
.open_line_4 {
  transform: translateY(-14px) rotate(-0.125turn);
}
