.cover {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.alignCenter {
  background-position: center !important;
}

.alignCenterCenter {
  background-position: center center !important;
}

.alignLeft {
  background-position: left !important;
}

.alignRight {
  background-position: right !important;
}
/* bottom variants  */
.alignBottomCenter {
  background-position: bottom center !important;
}

.alignBottomLeft {
  background-position: bottom left !important;
}

.alignBottomRight {
  background-position: bottom right !important;
}

.contain {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
}
