.buttonAnswer {
  margin-bottom: 0;
  margin-top: 0;
  transition: margin 0.3s;
}

.buttonAnswer:hover {
  margin-top: -1rem;
  margin-bottom: 1rem;
}
