.root {
  position: relative;
  width: 100%;
  filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.6));
  background-color: transparent !important;
}

.body {
  position: relative;
  height: auto;
  min-height: 80px;
  width: 100%;
  z-index: 1;
}

.body:focus {
  outline: none;
}

.selectedIcon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
