.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 180px 320px;
}

.innerContainer {
  position: relative;
  padding: 32px;
}

.contentCointainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 120px 80px;
}

.variantActionContentCointainer {
  padding: 34px 170px 104px 50px;
  overflow-y: visible;
}

.sizeSmall {
  width: 1280px !important;
  height: 720px !important;
}

.iconCross {
  opacity: 0;
  transition: 0.2s opacity;
}

.iconCrossHover {
  opacity: 1;
}

.buttonClose {
  z-index: 1;
}

.variantActionOuterContainer {
  padding: 247px 28px 168px !important;
}
