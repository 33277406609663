.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
}

.backgroundSizeCover {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundSizeContain {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
}

.backgroundSizeAuto {
  position: relative;
  background-repeat: no-repeat;
  background-size: auto;
}

.alignCenter {
  background-position: center !important;
}

.alignCenterCenter {
  background-position: center center !important;
}

.alignLeft {
  background-position: left !important;
}

.alignRight {
  background-position: right !important;
}
