.root {
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.reveal {
  /* needs to be a fixed value, but this can be increased if needed*/
  max-height: 9999px;
  padding-bottom: 40px;
}

.unreveal {
  max-height: 0;
  /* 
  since it's animating from the extreme max-height (see above), we want a function where almost all of the height is
  decreased very fast, but then leave enough time to give the illusion of a smooth closing

  Function was generated using trial and error here:
  https://matthewlein.com/tools/ceaser
  */
  transition-timing-function: cubic-bezier(0.000, 0.910, 0.015, 1.010);
  transition-duration: 0.5s;
}

