:root {
	--comment-input-background-color: lightgray;
	--comment-input-text-color: inherit;
}

.commentInput {
	background-color: var(--comment-input-background-color);
	color: var(--comment-input-text-color);
	padding: 1rem;
	padding-top: 2rem;
	position: relative;
}

:global(.program-type-slide) .commentInput {
	font-size: 28px;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	min-height: 500px;
	height: 1px;
	max-height: calc(100% - 2rem);
}

.fullHeight {
	height: 100%;
}

:global(.h-100):has(> .centered) {
	display: flex;
}

.centered {
	width: 100%;
	align-self: center;
}

.commentInput label {
	display: block;
	font-weight: bold;
	height: 100%;
}

.commentInput.commentInput label h2 {
	font-size: 0.9rem;
	line-height: 1;
	letter-spacing: 0;
	font-weight: bold;
	margin-bottom: 0;
}

:global(.program-type-scroll) label h2 {
	font-size: 0.75rem;
}

.characterCount {
	margin: 0;
	padding: 0;
	font-size: 0.75rem;
	font-weight: normal;
	font-style: italic;
	line-height: 1;
	visibility: hidden;
}

.commentInput label:has(textarea:focus) .characterCount {
	visibility: visible;
}

.commentInput textarea {
	display: block;
	width: 100%;
	color: inherit;

	resize: none;

	border: 1px solid rgba(0, 0, 0, 0.15);
	background-color: transparent;

	padding: 0.5rem;
	font-weight: normal;
}

.commentInput textarea:focus-visible {
	outline: none;
	background-color: rgba(255, 255, 255, 0.6);
}

:global(.program-type-slide) .commentInput textarea {
	font-size: 28px;
}
