.outerContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.innerContainer {
  position: relative;
}

.textContainer {
  position: relative;
}

.imagePosTop {
  flex-direction: column-reverse;
}

.imagePosBottom {
  flex-direction: column;
}

.imagePosLeft {
  flex-direction: row-reverse;
}

.imagePosRight {
  flex-direction: row;
}

.qrCode {

}

.qrCode .qrCodeText {
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: 1px;
}