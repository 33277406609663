.root {
  position: relative;
}

:global(.size-xs .speech-bubble p > p) {
  margin: 0;
}

:global(.speech-bubble),
:global(.speech-bubble-sm),
:global(.speech-bubble p),
:global(.speech-bubble-sm p) {
  font-family: var(--font-family-sans-serif);
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}

:global(.speech-bubble-emphasized),
:global(.speech-bubble-emphasized p),
:global(.speech-bubble-emphasized-sm p),
:global(.speech-bubble-emphasized-sm) {
  font-family: var(--font-family-sans-serif);
  font-size: 2.625rem;
  letter-spacing: -0.05rem;
  line-height: 2.8125rem;
  text-transform: uppercase;
}

.speechBubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 1.5rem;
  bottom: 0;
  transition: 0.4s;
}

.speechBubbleSlide {
  border-radius: 40px;
}

:global(.size-xs) .speechBubble {
  border-radius: 1rem;
}

:global(.size-xs .speech-bubble-sm) {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.speechBubbleContainer {
  position: relative;
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noFillet {
  border-radius: 0 !important;
}

.contentContainer {
  position: relative;
}

.arrowDown {
  position: absolute;
  bottom: -13px;
  left: 49%;
  width: 0;
  height: 0;
  border: 26px solid transparent;
  border-bottom: 0;
  border-left: 0;
  margin-left: -13px;
  margin-bottom: -13px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  transition: 0.4s;
}

.arrowDownLeft {
  position: absolute;
  bottom: -13px;
  left: 60px;
  width: 0;
  height: 0;
  border: 26px solid transparent;
  border-bottom: 0;
  border-left: 0;
  margin-left: -13px;
  margin-bottom: -13px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  transition: 0.4s;
}

.arrowDownRight {
  position: absolute;
  bottom: -13px;
  right: 60px;
  width: 0;
  height: 0;
  border: 26px solid transparent;
  border-bottom: 0;
  border-right: 0;
  margin-right: -13px;
  margin-bottom: -13px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  transition: 0.4s;
}

.arrowUp {
  position: absolute;
  top: -13px;
  left: 48%;
  width: 0;
  height: 0;
  border: 26px solid transparent;

  border-top: 0;
  border-right: 0;
  margin-left: -13px;
  margin-top: -13px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  transition: 0.4s;
}

.arrowDownSmall {
  bottom: -7px;
  border: 14px solid transparent;
  margin-left: -7px;
  margin-bottom: -7px;
}

.arrowNo {
  visibility: hidden;
}

/* VARIANT "SQUARE" */

.smallArrowDown {
  position: absolute;
  left: 15%;
  bottom: -11px;
}

.smallArrowUp {
  position: absolute;
  right: 15%;
  top: -11px;
  transform: rotate(180deg);
}
