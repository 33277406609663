.root {
  position: relative;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
}

.top {
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.bottom {
  position: relative;
  width: 100%;
  flex-grow: 0;
}
