.text {
	color: var(--text-color, inherit);
}

.caution {
	--text-color: var(--fp-caution);
}

.positive {
	--text-color: var(--fp-positive);
}

.critical {
	--text-color: var(--fp-critical);
}

.info {
	--text-color: var(--fp-info);
}

.neutral {
	--text-color: inherit;
}

.brandAccent {
	--text-color: var(--fp-interaction);
}

.promote {
	--text-color: var(--fp-primary);
}
