:root {
	--slider-color: var(--fp-neutral);
	--slider-rail-color: var(--fp-neutral-light);
	--slider-dot-width: 1px;
	--slider-dot-height: 8px;
	--slider-handle-size: 30px;
	--slider-rail-height: 1px;
}

:global(.program-type-slide) {
	--slider-rail-height: 10px;
	--slider-handle-size: 36px;
}

.slider {
	height: calc(var(--slider-handle-size) + 1rem);
	padding-top: calc(
		var(--slider-handle-size) / 2 - var(--slider-rail-height) / 2
	);
	padding-bottom: 0;
	width: calc(100% - var(--slider-dot-width));
	padding-left: calc(var(--slider-dot-width) / 2);
	padding-right: calc(var(--slider-dot-width) / 2);
}

.slider :global(.rc-slider-mark) {
	position: relative;
	top: calc(var(--slider-handle-size) / 2);
	font-style: italic;
	font-size: 1rem;
	height: calc(1rem + 4px);
}

.slider:global(.rc-slider-disabled) {
	background-color: initial;
}

.slider:global(:not(.rc-slider-disabled)) :global(.rc-slider-handle):active {
	box-shadow: 0 0 5px var(--slider-color);
}

.slider :global(.rc-slider-step) {
	height: var(--slider-rail-height);
}

.brand {
	--slider-color: var(--fp-interaction);
	--slider-rail-color: var(--fp-neutral-light);
}

.neutral {
	/**
	Intentionally left blank since the default values are using
	the neutral colors which allow us to override the colors if we would like.
	**/
}

.neutral:global(:not(.rc-slider-disabled)):active {
	--slider-color: var(--fp-interaction);
}

@media (hover: hover) {
	/**
	We use media query hover to only apply the :hover effect on browsers
	with "real" hover states. This minimizes problem on touch devices
	where the :hover state can sometime linger even after the user
	has released their finger from the component.
	**/
	.neutral:global(:not(.rc-slider-disabled)) :global(.rc-slider-handle):hover {
		--slider-color: var(--fp-interaction);
	}

	/**
	On browsers that support `:has`, we scope the color to the whole component so
	that the track gets the same color as the slider handle.
	**/
	.neutral:global(:not(.rc-slider-disabled)):has(:global(.rc-slider-handle):hover) {
		--slider-color: var(--fp-interaction);
	}
}

.critical {
	--slider-color: var(--fp-critical);
	--slider-rail-color: var(--fp-critical-light);
}

.positive {
	--slider-color: var(--fp-positive);
	--slider-rail-color: var(--fp-positive-light);
}

.caution {
	--slider-color: var(--fp-caution);
	--slider-rail-color: var(--fp-caution-light);
}
